<template>
	<div>
		<SettingsStudentEmails :student-emails="studentEmails" :max-student-emails="currentUser.limits ? currentUser.limits.studentEmails : 0" />
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: `${this.$t('nav.settings')} - ${this.$t('setup.student')}`,
		}
	},
	components: {
		SettingsStudentEmails: () => import('@/components/settings/SettingsStudentEmails.vue'),
	},
	computed: {
		...mapGetters({
			currentUser: 'user/currentUser',
			studentEmails: 'studies/studentEmails',
		}),
	},
	created() {
		this.fetchStudentEmails()
	},
	methods: {
		...mapActions('studies', ['fetchStudentEmails']),
	},
}
</script>
